// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_container__X02md {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 50vh;\r\n}\r\n\r\n.Loader_loader__\\+lRPl {\r\n  border: 4px solid #ffc700;\r\n  border-radius: 50%;\r\n  border-top: 4px solid transparent;\r\n  width: 50px;\r\n  height: 50px;\r\n  animation: Loader_spin__E4RJ0 0.8s linear infinite;\r\n}\r\n\r\n@keyframes Loader_spin__E4RJ0 {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iCAAiC;EACjC,WAAW;EACX,YAAY;EACZ,kDAAoC;AACtC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 50vh;\r\n}\r\n\r\n.loader {\r\n  border: 4px solid #ffc700;\r\n  border-radius: 50%;\r\n  border-top: 4px solid transparent;\r\n  width: 50px;\r\n  height: 50px;\r\n  animation: spin 0.8s linear infinite;\r\n}\r\n\r\n@keyframes spin {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Loader_container__X02md",
	"loader": "Loader_loader__+lRPl",
	"spin": "Loader_spin__E4RJ0"
};
export default ___CSS_LOADER_EXPORT___;
